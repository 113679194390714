<template>
  <div class="classfiy">
    <van-nav-bar left-arrow @click-left="$router.back(-1)">
      <template #title>
        <van-search
          v-model="searchValue"
          placeholder="请输入商品名称/类型"
          background="none"
          :clearable="false"
          @search="openJdSeacrchFn($event, 'input', '', searchValue, '')"
        />
      </template>
      <template #right>
        <van-button
          @click="openJdSeacrchFn($event, 'input', '', searchValue, '')"
          color="#FF4242"
          >搜索</van-button
        >
      </template>
    </van-nav-bar>
    <div class="jd-classify">
      <div class="classifyWrap" ref="classifyWrap">
        <div>
          <!-- 分类选择 -->
          <van-tree-select
            :height="treeSelectHeight"
            :items="categoryList"
            v-model:main-active-index="active"
          >
            <!-- 自定义右侧内容 -->
            <template #content>
              <van-tabs
                v-model="right_active"
                scrollspy
                :ellipsis="false"
                :sticky="true"
                offset-top="43px"
              >
                <van-tab
                  v-for="(item, index) in rightList"
                  :title="item.text"
                  :key="index"
                >
                  <div>
                    <div class="typeItem" v-if="rightList.length">
                      <div :key="index">
                        <div class="typeItem-title">
                          {{ item.text }}
                        </div>
                        <div class="typeItem-children-txt">
                          <div
                            v-for="(itemSecond, indexSecond) in item.children"
                            :key="indexSecond"
                          >
                            <div>
                              <div
                                class="on-line"
                                @click="
                                  openJdSeacrchFn(
                                    $event,
                                    'typeEnv',
                                    itemSecond.id,
                                    '',
                                    itemSecond.text
                                  )
                                "
                              >
                                {{ itemSecond.text }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="flex c-999 no-select-box">暂无分类</div>
                  </div>
                </van-tab>
              </van-tabs>
              <!-- <div class="text-center no-bottom">— 到底啦 —</div> -->
            </template>
          </van-tree-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { jdCategoryList } from "@/api/shop";
export default defineComponent({
  name: "classfiy",
  created() {
    let app = getCurrentInstance();
    app.proxy.jdCategoryListFn();
  },
  setup() {
    const $router = useRouter();
    const state = reactive({
      categoryList: [],
      active: 0,
      right_active: 0,
      searchValue: "",
      treeSelectHeight: document.body.offsetWidth - 60,
    });
    const rightList = computed(() => {
      return state.categoryList[state.active]?.children;
    });
    const jdCategoryListFn = async () => {
      let rs = await jdCategoryList();
      state.categoryList = rs.data;
    };
    const openJdSeacrchFn = (e, type, id, text, placeholder) => {
      if (type === "typeEnv") {
        $router.push({
          path: "/jdshop/jdsearch",
          query: {
            type,
            id,
            text,
            placeholder,
          },
        });
      } else {
        if (state.searchValue.trim()) {
          $router.push({
            path: "/jdshop/jdsearch",
            query: {
              type,
              id,
              text,
            },
          });
        }
      }
    };
    return {
      rightList,
      jdCategoryListFn,
      openJdSeacrchFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.classfiy {
  .van-nav-bar {
    .van-button {
      height: 30px;
      border-radius: 4px;
      border: 1px solid #ff4e4e;
      color: #ff4e4e;
      font-size: 14px;
    }
    ::v-deep(.van-nav-bar__left) {
      left: -10px;
      top: -5px;
      .van-icon {
        font-size: 22px;
        color: #333;
        padding-top: 4px;
      }
    }
    ::v-deep(.van-nav-bar__title) {
      width: 273px;
      margin: 0 30px;
      max-width: 100%;
      .van-search {
        height: 30px;
        .van-search__content {
          background: #f2f2f2;
          border-radius: 8px;
        }
        .van-icon {
          color: #333;
        }
      }
    }
  }
  .jd-classify {
    position: relative;
    .classifyWrap {
      background: #f6f6f6;
    }
    ::v-deep(.van-tree-select) {
      height: calc(100vh - 50px) !important;
      .van-tree-select__nav {
        width: 110px;
        flex: none;
      }
      .van-sticky--fixed {
        width: 265px !important;
        z-index: 1;
        top: 46px !important;
      }
      .van-sidebar-item {
        background-color: #f6f6f6;
      }
      .van-tabs__nav {
        background-color: #f6f6f6;
      }
      .van-tabs__content {
        background: #f6f6f6;
        .van-tab__panel {
          .on-line {
            width: 65px;
            height: 25px;
            border-radius: 4px;
            line-height: 25px;
          }
          .typeItem {
            background-color: #fff;
            box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            margin-top: 10px;
            margin-right: 10px;
            .typeItem-title {
              font-size: 16px;
              color: #333;
              font-weight: bold;
              padding: 10px;
            }
            .typeItem-children-txt {
              display: flex;
              flex-wrap: wrap;
              padding-bottom: 10px;
              .on-line {
                width: 73px;
                height: 25px;
                background: #f4f4f4;
                border-radius: 4px;
                margin: 5px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 2px;
              }
            }
          }
        }
      }
      .van-tree-select__content {
        background-color: #f6f6f6;
      }
      .van-tab {
        padding: 0px 3px;
        flex: none;
        .van-tab__text {
          background: #eeeeee;
          border-radius: 12px;
          border: 1px solid #eeeeee;
          padding: 2px 6px;
          color: #333333;
          font-size: 12px;
        }
      }
      .van-tab--active {
        .van-tab__text {
          background: #ffeaea;
          border-radius: 12px;
          border: 1px solid #ff4242;
          color: #ff4242;
        }
      }
      .van-tabs__line {
        display: none;
      }
      .mt-10 {
        margin-top: 50px;
      }
      .van-sidebar-item {
        text-align: center;
      }
      .van-sidebar-item--select {
        &::before {
          width: 0px;
          height: 0px;
        }
        .van-sidebar-item__text {
          color: #ff4242;
          font-size: 14px;
        }
      }
    }
  }
  .no-bottom {
    text-align: center;
    padding: 10px 0px;
  }
}
</style>
